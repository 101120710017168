<template>
  <div class="container container-table">
    <div class="columns is-centered is-multiline">
      <div class="container column is-8">
        <div style="max-width:18em; margin: 0 auto;">
          <a href="/" class="edshedlogo">
            <img src="/img/logo-edshed.png" alt="Logo" class="img-responsive center-block">
          </a>
        </div>
        <div class="center-block" style="width: fit-content; text-align: center">
          <img src="/img/logo.png" class="inline-block sublogo">
          <img v-if="$route.query.locale == 'en_US'" src="/img/math-shed-logo-dark.png" class="inline-block sublogo mathslogo">
          <img v-else src="/img/maths-shed-logo-dark.png" class="inline-block sublogo mathslogo">
        </div>
      </div>
      <!-- <img src="/img/Bee.svg" alt="Logo" class="img-responsive center-block logo"> -->
      <div class="column is-8">
        <div class="box box-info" style="color:black">
          <div class="box-header content with-border">
            <div class="text-center">
              <h1>{{ this.$route.name.toUpperCase() }}</h1>
            </div>
          </div>
          <div class="box-body">
            <div v-if="message" class="content">
              <h3>{{ message }}</h3>
            </div>
            <div v-if="data">
              <div class="content">
                <h2>Hi {{ data.name }}</h2>
                <p><small>@{{ data.username }} / {{ data.email }}</small></p>
                <h3>Please enter a new password below:</h3>
              </div>

              <div class="field">
                <label for="password" class="label">Password*</label>
                <div class="is-clearfix">
                  <password v-model="password" class="control" style="max-width: inherit;" @score="showScore" @feedback="showFeedback" />
                </div>
                <p v-for="fb in suggestions" v-if="!warning" class="notification">
                  💡{{ fb }}
                </p>
                <p v-if="warning" class="notification is-danger">
                  ⚠️ {{ warning }}
                </p>
              </div>
              <div class="control has-text-centered">
                <button :class="{'button':true, 'is-link':true, 'is-loading': loading}" :disabled="password == '' || score < 2 || warning || password.length < 7" @click="setPassword">
                  Submit
                </button>
                <p v-if="score < 2 || warning != ''" class="moreMargin">
                  <small class="tag is-danger"><span class="icon"><i class="ion ion-ios-warning" /></span> <span>A more secure password is required.</span></small>
                </p>
                <p v-if="password.length < 7" class="moreMargin">
                  <small class="tag is-danger"><span class="icon"><i class="ion ion-ios-warning" /></span> <span>Passwords must be 7 characters or more.</span></small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
          &nbsp;
    </div>
    <div class="row">
      <div class="container">
        <div class="text-center col-md-4 col-sm-offset-4">
          <p style="font-size:14px; color: #4a4a4a;">
            Copyright &copy; {{ new Date().getFullYear() }} Education Shed Ltd.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Password from 'vue-password-strength-meter'
import { request } from '@/edshed-common/api'

export default {
  name: 'PasswordResetView',
  components: {
    Password
  },
  data () {
    return {
      message: 'Loading data...',
      data: null,
      password: '',
      warning: '',
      suggestions: [],
      score: 0,
      loading: false
    }
  },
  mounted () {
    // get code. If no code
    this.$nextTick(() => {
      this.getRequestData()
    })
  },
  methods: {
    getRequestData () {
      if (this.$router.history.current.query && this.$router.history.current.query.key) {
        const key = this.$router.history.current.query.key
        console.log(key)
        // send request
        request('GET', 'users/me/passwordreset?key=' + key, null)
          .then((response) => {
            const data = response.data
            console.log(data)
            if (data.error) {
              this.message = data.error
              return
            }
            this.data = data.data
            this.message = ''
          })
          .catch((error) => {
            console.log(error)
            this.message = error
            if (error.response && error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
          })
      } else {
        this.message = 'Invalid request'
        // this.$router.push('/logout')
      }
    },
    showFeedback ({ suggestions, warning }) {
      console.log('🙏', suggestions)
      console.log('⚠', warning)
      this.suggestions = suggestions
      this.warning = warning
    },
    showScore (score) {
      console.log('💯', score)
      this.score = score
    },
    setPassword () {
      if (this.password != '' && this.score >= 2 && !this.warning && this.password.length >= 7) {
        const key = this.$router.history.current.query.key
        const password = this.password

        request('POST', 'users/me/passwordreset', { key, password })
          .then((response) => {
            const data = response.data
            console.log(data)
            if (data.error) {
              this.message = data.error
              return
            }
            this.$buefy.toast.open({ message: 'Success', type: 'is-success' })
            this.login()
          })
          .catch((error) => {
            console.log(error)
            this.message = error
            if (error.response && error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
          })
      }
    },
    login () {
      const username = this.data.username
      const password = this.password
      request('POST', 'auth', { username, password })
        .then((response) => {
          window.console.log('Response', response)
          const data = response.data
          /* Checking if error object was returned from the server */
          if (data.error) {
            const errorName = data.error.name
            if (errorName) {
              this.message = errorName === 'InvalidCredentialsError'
                ? 'Username / Password incorrect. Please try again.'
                : errorName
            } else {
              this.message = data.error
            }

            return
          }

          /* Setting user in the state and caching record to the localStorage */
          if (data.user) {
            const token = data.user.token
            this.$store.commit('SET_USER', data.user)
            this.$store.commit('SET_TOKEN', token)

            // console.log('token: ' + token)
            // console.log('user: ' + JSON.stringify(data.user))

            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
              window.localStorage.setItem('token', token)
            }

            this.$router.push(data.redirect ? data.redirect : '/')
          }
        })
        .catch((error) => {
          console.log(error)

          this.message = 'Password incorrect or user not found'
        })
    }
  }
}
</script>
